import BooksComponents from "./BooksComponent";

const Books = () => {
  return(
    <div className="home">
    <BooksComponents />
  </div>
  )
};

export default Books;
