import Pagination from "../Components/Pagination";
import Post from "../Components/Post";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';

//renders a json file. After search method, renders the filtered json file.
const Home = ({ onPageChange }) => {
  const [posts, setPosts] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [commentCount, setCommentCount] = useState([]);
  


  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch(`/api/posts/allposts?page=${currentPage}&keyword=${0}&subtitle=${0}`);
      const json = await response.json();

      if (response.ok) {
        const totalPosts = json[0].totalPosts;
        if (totalPosts) {
          setTotalPages(Math.ceil(totalPosts / 5));
          json.shift();
        }
        setPosts(json);

        const commentCountPromises = json.map((post) =>
          axios
            .get(`/api/posts/${post.id}/comments`)
            .then((response) => response.data.length)
        );

        Promise.all(commentCountPromises)
          .then((commentCounts) => setCommentCount(commentCounts))
          .catch((error) => console.error("Error fetching comments:", error));
      }
    };

    fetchPosts();
  }, [currentPage]);

  if (!posts || !commentCount) {
    return <p className="loading-data">loading data...</p>;
  }
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    onPageChange(newPage);
  };

  return (
    <div className="home">
      {posts.map((post, index) => (
        <Post
          key={index}
          jsonData={post}
          id={post.id}
          commentCount={commentCount}
          i={index}
        />
      ))}
      <Pagination
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={totalPages}
      />
      {console.log(commentCount)}
    </div>
  );
};

export default Home;
