import { useEffect, useState } from "react";
import garbage from "../images/garbage_can.png";
import garbage2 from "../images/garbage_can6.png";
import axios from "axios";
import Modal from "./Modal";

export default function SingleComment({
  username,
  commentedDate,
  body,
  commentId,
  onDeleteComment,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [myemail, setEmail] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
 
  useEffect(() => {
    const timestamp = commentedDate;
    const dateObj = new Date(timestamp);

    setDate(dateObj.toISOString().split("T")[0]);
    setTime(dateObj.toISOString().split('T')[1].split('.')[0].split(':').slice(0, 2).join(':'));
  });

  const onClose = () => {
    setIsOpen(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const modalHandler = () => {
    setIsOpen(true);
    axios
      .get(`/api/${commentId}`)
      .then((response) => {
        setEmail(response.data.email);
        console.log(response.data.email);
      })
      .catch((error) => {
        console.log("Error fetching comment", error);
      });
  };

  const deleteCommentHandler = (email) => {
    if (email === myemail) {
      onDeleteComment(commentId);
      axios
        .delete(`/api/${commentId}`)
        .then((response) => {
          console.log("Comment deleted:", response.data);
        })
        .catch((error) => {
          console.error("Error deleting comment:", error);
        });
      setIsOpen(false);
    } else {
    }
  };

  return (
    <div className="single-comment-wrapper">
      <div className="single-comment">
        <div className="comment-header">
          <p className="username">{username}</p>
          <p className="commented-date">{date}</p>
          <p className="commented-date">{time}</p>
        </div>
        <div className="comment-body">{body}</div>
        <button onClick={modalHandler}>
          <img
            id="myImage"
            src={isHovered ? garbage2 : garbage}
            alt=""
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </button>
      </div>
      <div className="line-breaking"></div>
      <Modal
        open={isOpen}
        onClose={onClose}
        deleteCommentHandler={deleteCommentHandler}
      />
    </div>
  );
}
