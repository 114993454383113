import { BrowserRouter, Routes, Route} from "react-router-dom";
import { useState, useEffect } from "react";
import Home from "./pages/Home";
import Header from "./Components/Header";
import About from "./pages/About";
import Namecard from "./Components/Namecard";
import Searchbar from "./Components/Searchbar";
import Footer from "./Components/Footer";
import TagBar from "./Components/TagBar";
import SinglePost from "./Components/SinglePost";
import HomeHeading from "./Components/HomeHeading";
import Books from "./Components/Books";
import Journals from "./Components/Journals";
import SearchResults from "./pages/SearchResults";

function App() {
  



  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
  
        <div className="quote">          
          <HomeHeading currentPage={currentPage}/>
          <p className="p2">在这道路的前方，大概就是，美好的每一天。</p>
        </div>

        <div className="pages">
          <Routes>
            <Route path="/" element={<Home onPageChange={handlePageChange}/> } />
            <Route path="/About" element={<About/>} />
            <Route path="/posts/:id" element={<SinglePost/>}/>
            <Route path="/Books" element={<Books/>}/>
            <Route path="/Journals" element={<Journals/>}/>
            <Route path="/SearchResults" element={<SearchResults onPageChange={handlePageChange}/>}/>
          </Routes>

          <div className="sidebar">
            <Namecard />
            <div style={{padding: '40px'}}/>
            <Searchbar/>
            <div style={{paddingBottom: '40px'}}/>
            <TagBar/>
          </div>
        </div>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
