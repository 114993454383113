import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
function HomeHeading({ heading, postHeading, currentPage }) {
  const location = useLocation();

  return (
    <p className="p1">
      -------
      <Link className="heading" to="/">
        {location.pathname === "/" ? "Home" : "Home/"}
      </Link>
      {location.pathname === "/" ? "" : location.pathname[1].toUpperCase() + location.pathname.slice(2)}
    </p>
  );
}

export default HomeHeading;
