import timestamp from "../images/Timestamp.png"
import visit from "../images/VisitCount.png"
import comment from "../images/CommentCount.png"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react";
import axios from 'axios';

export default function Post({id, jsonData, commentCount, i}) {

  

  // const body = jsonData.body.substring(0, 100);
  let body = jsonData.body;

  // Define a regular expression to match non-English characters
  const nonEnglishCharsRegex = /[^\x00-\x7F]+/;

  // Check if jsonData.body contains non-English characters
  if (nonEnglishCharsRegex.test(jsonData.body)) {
    // If non-English characters are found, get the first 250 characters
    body = jsonData.body.substring(0,80);
  } else {
    // If no non-English characters are found, you can use the entire jsonData.body
    body = jsonData.body.substring(0,250);
  }




  return (
    <div className="post" >
      
      <div className="titlebar">
        <div className="tagline"></div>
        <div className="tag">
        <button>{jsonData.majTag}</button>
        </div>
        <Link className="title" to={`/posts/${id}`} state={{ jsonData }}>
        <p>{jsonData.title}</p>
      </Link>
      </div>
      <div className="body">{body}...</div>
     <div className="data-wrapper">

     <div className="data">
        <img src={timestamp} style={{height:'1.2rem'}}alt=""/>
        <h2>{jsonData.date}</h2>
        {/* <img src={visit} style={{height:'1.4rem'}}alt=""/>
        <h2>1 visits</h2> */}
        <img src={comment} style={{height:'1.4rem'}}alt=""/>
        <h2>{commentCount[i]} comment</h2>
      </div>
     </div>
    </div>
  );
}
