import { useNavigate  } from "react-router-dom";

export default function Tag(props) {
  const navigate = useNavigate();
  function tagHandler() {
    navigate("/searchResults", { state: { val: 0, sub: props.topic} });
  }

  return (
    <button onClick={tagHandler} className="tagbar-tag">
      <p>{props.topic}</p>
      <p id="num">/ {props.frequency} </p>
    </button>
  );
}
