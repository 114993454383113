import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { useState } from "react";
import tagsData from "../json/tags.json";
import { useNavigate  } from "react-router-dom";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const tags = tagsData.tags;

  const dropdownMenuAnimation = {
    animationName: "downOut",
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
    // animationFillMode: "forwards",
  };
  const navigate = useNavigate();
  function ClickHandler(e){
    navigate("/searchResults", { state: { val: 0, sub: e} });
  }

  return (
    <header>
      <div className="container">
        <div className="container1">
          <Link to="/">
            <h1 className={styles.mainmenu}>Nana<span style={{fontSize:"25px", alignSelf:"center", paddingTop: "6px"}}>♡</span>Blog</h1>
          </Link>
        </div>

        <div className="container2">
          <Link to="/">
            <h1 className={styles.navmenu}>Home</h1>
          </Link>

          <div
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            className={`dropdown ${open ? "open" : ""}`}
          >
            <Link className="navmenu">
              <h1 className={styles.navmenu}>
                Codes<span className="drop-down">▽</span>
              </h1>
            </Link>
            <div
              className={`menu ${open ? "open" : ""}`}
              style={open ? dropdownMenuAnimation : {}}
            >
              {tags.map((tag, index) => (
                <button onClick={() => ClickHandler(tag.topic)} key={index}>{tag.topic}</button>
              ))}
            </div>
            <style>
              {`
              @keyframes downOut {
                0% {
                  transform: translateZ(100px)
                  translateX(-7px)  translateY(-10px);
                }
                70% {
                  transform: translateZ(-10px) 
                  translateX(-7px)translateY(0px);
                }
                100% {
                  transform: translateZ(0px) 
                  translateX(-7px)translateY(0px);
                }
              }
            `}
            </style>
          </div>

          <div
            onMouseEnter={() => setOpen2(true)}
            onMouseLeave={() => setOpen2(false)}
            className={`dropdown ${open2 ? "open2" : ""}`}
          >
            <Link className="navmenu">
              <h1 className={styles.navmenu}>
                Shelf<span className="drop-down">▽</span>
              </h1>
            </Link>
            <div
              className={`menu ${open2 ? "open2" : ""}`}
              style={open2 ? dropdownMenuAnimation : {}}
            >
             <Link to="/journals">
             <button className="button">
                Journals
              </button>
              </Link>
              <Link to="/books">
              <button >Books</button>
              </Link>
              
            </div>
            <style>
              {`
              @keyframes downOut {
                0% {
                  transform: translateZ(200px) translateX(-7px) translateY(-10px);
                }
                70% {
                  transform: translateZ(-10px) translateX(-7px) translateY(0px);
                }
                100% {
                  transform: translateZ(0px) translateX(-7px) translateY(0px);
                }
              }
              `}
            </style>
          </div>

          <Link to="/about">
            <h1 className={styles.navmenu}>About</h1>
          </Link>
          <Link>
            <h1 id="jp" className={styles.navmenu2}>
              素晴らしき日々
            </h1>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
