export default function PaginationButton({pagenum, id, click, isActive}){


  const buttonClass = isActive ? 'pagination-button active' : 'pagination-button';
  
  return(
    <button id={id}  className={buttonClass} onClick={() => click(pagenum)} >
      {pagenum}
      {/* {console.log(isActive, pagenum)} */}
    </button>
  )
}
//className="pagination-button"
// className={{buttonClass}}