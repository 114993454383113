// import React, { useState } from "react";
// import PaginationButton from "./PaginationButton";

// export default function Pagination({ currentPage, onPageChange, totalPages }) {
//   const maxButtonsToShow = 5;
//   const [activeButton, setActiveButton] = useState(currentPage);
//   const [visibleButtons, setVisibleButtons] = useState(
//     Array.from({ length: maxButtonsToShow }, (_, index) => index + 1)
//   );

//   const handlePageChange = (newPage) => {
//     if (newPage < 1) return; // Ensure page doesn't go below 1
//     setActiveButton(newPage);
//     onPageChange(newPage);
//   };

//   const handleDotClick = () => {
//     // Toggle the "..." button to show the next set of numbers
//     const nextSet = visibleButtons[maxButtonsToShow - 2] + 1;
//     const newButtons = Array.from(
//       { length: maxButtonsToShow },
//       (_, index) => nextSet + index
//     );

//     setActiveButton(newButtons[0]);
//     setVisibleButtons(newButtons);
//   };

//   return (
//     <div className="pagination">
//       <PaginationButton
//         id={"arrow"}
//         pagenum={"<<"}
//         click={() => handlePageChange(currentPage - 1)}
//       />
//       {/* <PaginationButton pagenum={1} click={onPageChange} />
//       <PaginationButton pagenum={2} click={onPageChange} />
//       <PaginationButton pagenum={3} />
//       <PaginationButton pagenum={"…"} />
//       <PaginationButton pagenum={5} /> */}
//             {visibleButtons.map((pageNum) => (
//         <PaginationButton
//           key={pageNum}
//           pagenum={pageNum}
//           click={() => handlePageChange(pageNum)}
//           isActive={pageNum === activeButton}
//         />
//       ))}
//       {visibleButtons[maxButtonsToShow - 1] < totalPages && (
//         <PaginationButton pagenum={"..."} click={handleDotClick} />
//       )}
//       <PaginationButton
//         id={"arrow"}
//         pagenum={">>"}
//         click={() => handlePageChange(currentPage + 1)}
//       />
//     </div>
//   );
// }





















// import React, { useState, useEffect } from "react";
// import PaginationButton from "./PaginationButton";

// export default function Pagination({ currentPage, onPageChange, totalPages }) {
//   const maxButtonsToShow = 5;
//   const [activeButton, setActiveButton] = useState(currentPage);
//   const [visibleButtons, setVisibleButtons] = useState([]);

//   // Update visibleButtons when currentPage or totalPages changes
//   useEffect(() => {
//     updateVisibleButtons(currentPage, totalPages);
//   }, [currentPage, totalPages]);

//   const updateVisibleButtons = (newPage, total) => {
//     if (newPage < 1) newPage = 1;
//     const middleButton = 3;
//     const newButtons = [];

//     if (total <= maxButtonsToShow) {
//       // If there are fewer pages than maxButtonsToShow, display all pages
//       newButtons.push(...Array.from({ length: total }, (_, index) => index + 1));
//     } else if (newPage <= middleButton) {
//       // If current page is near the beginning, display buttons 1 to maxButtonsToShow - 1 and total
//       newButtons.push(...Array.from({ length: maxButtonsToShow - 1 }, (_, index) => index + 1));
//       if (total > maxButtonsToShow) {
//         newButtons.push("...");
//         newButtons.push(total);
//       }
//     } else if (newPage >= total - middleButton) {
//       // If current page is near the end, display buttons (total - maxButtonsToShow + 2) to total
//       if (total > maxButtonsToShow) {
//         newButtons.push(1);
//         newButtons.push("...");
//       }
//       newButtons.push(...Array.from({ length: maxButtonsToShow - 1 }, (_, index) => total - maxButtonsToShow + 2 + index));
//       newButtons.push(total);
//     } else {
//       // Display buttons around the current page
//       newButtons.push(1);
//       newButtons.push("...");
//       const start = newPage - middleButton;
//       const end = newPage + middleButton;
//       newButtons.push(...Array.from({ length: maxButtonsToShow - 3 }, (_, index) => start + index));
//       newButtons.push("...");
//       newButtons.push(total);
//     }

//     setActiveButton(newPage);
//     setVisibleButtons(newButtons);
//   };

//   const handlePageChange = (newPage) => {
//     if (newPage < 1 || newPage === activeButton || newPage > totalPages) return;
//     onPageChange(newPage);
//   };

//   return (
//     <div className="pagination">
//       <PaginationButton
//         id={"arrow"}
//         pagenum={"<<"}
//         click={() => handlePageChange(currentPage - 1)}
//       />
//       {visibleButtons.map((pageNum, index) => (
//         <PaginationButton
//           key={index}
//           pagenum={pageNum}
//           click={() => handlePageChange(pageNum)}
//           isActive={pageNum === activeButton}
//           isDots={pageNum === "..."}
//         />
//       ))}
//       <PaginationButton
//         id={"arrow"}
//         pagenum={">>"}
//         click={() => handlePageChange(currentPage + 1)}
//       />
//     </div>
//   );
// }
import React, { useState, useEffect } from "react";
import PaginationButton from "./PaginationButton";

export default function Pagination({ currentPage, onPageChange, totalPages }) {
  const maxButtonsToShow =  Math.min(totalPages, 5);
  const [visibleButtons, setVisibleButtons] = useState([]);

  useEffect(() => {
    updateVisibleButtons(currentPage);
  }, [currentPage]);

  const updateVisibleButtons = (newPage) => {
    if (newPage < 1) newPage = 1;
    let start, end;

    if (newPage <= Math.ceil(maxButtonsToShow / 2)) {
      start = 1;
      end = maxButtonsToShow;
    } else if (newPage >= totalPages - Math.floor(maxButtonsToShow / 2)) {
      start = totalPages - maxButtonsToShow + 1;
      end = totalPages;
    } else {
      start = newPage - Math.floor(maxButtonsToShow / 2);
      end = newPage + Math.floor(maxButtonsToShow / 2);
    }

    const newButtons = Array.from({ length: maxButtonsToShow }, (_, index) => start + index);
    setVisibleButtons(newButtons);
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages || newPage === currentPage) return;
    onPageChange(newPage);
  };

  return (
    <div className="pagination">
      <PaginationButton
        id={"arrow"}
        pagenum={"<<"}
        click={() => handlePageChange(currentPage - 1)}
      />
      {visibleButtons.map((pageNum, index) => (
        <PaginationButton
          key={index}
          pagenum={pageNum}
          click={() => handlePageChange(pageNum)}
          isActive={pageNum === currentPage}
        />
      ))}
      <PaginationButton
        id={"arrow"}
        pagenum={">>"}
        click={() => handlePageChange(currentPage + 1)}
      />
    </div>
  );
}
