// import pfp from "../images/pfp4.jpg";
import { Document, Page  } from 'react-pdf'
import { pdfjs } from 'react-pdf';
import { useState } from 'react';
// import pdf from "../pdf/Tractatus_Logico-Philosophicus.pdf"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export default function BooksComponents() {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(7);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function changePage(offSet){
    setPageNumber(prevPageNumber => prevPageNumber + offSet);
  }
  function changePageBack(){
    changePage(-1)
  }
  function changePageForward(){
    changePage(1)
  }
  
  return (
    <div className="post" style={{ height: "100%" }}>
     <div className="aboutme">
        <div className="single-post"></div>
        <div className="title2">Tractatus Logico-Philosophicus</div>
        <div className="aboutmedate">Ludwig Wittgenstein</div>
        <div className='body'>
          <div className="text">
          <span id='title'>1</span>The world is everything that is the case.
          </div>
          <div className="text">
          <span id='title'>1.1</span>The world is the totality of facts, not of things.
          </div>
          <div className="text">
          <span id='title'>1.11</span>The world is determined by the facts, and by these being all the facts.
          </div>
          <div className="text">
          <span id='title'>1.12</span>For the totality of facts determines both what is the case, and also all that is not the case.
          </div>
          <div className="text">
          <span id='title'>1.13</span>The facts in logical space are the world. 
          </div>
          <div className="text">
          <span id='title'>1.2</span>The world divides into facts.
          </div>
          <div className="text">
          <span id='title'>1.21</span>Any one can either be the case or not be the case, and everything else remain the same. 
          </div>





          
        </div>
{/* 

        <Document  file={pdf} onLoadSuccess={onDocumentLoadSuccess} >
        <Page renderTextLayer={false} renderAnnotationLayer={false} pageNumber={pageNumber} 
         scale={2}
         width={600}/>
      </Document>
      <p>
        Page {pageNumber} of {numPages}
        <button onClick={changePageBack}>P</button>
        <button onClick={changePageForward}>N</button>
      </p> */}


        <div className="line-breaking"></div>
        <div className="post-footer">
          <div className="last-updated">Last Updated 2023-12-25</div>
        </div>
      </div>
    </div>
  );
}
