import { useRef, useState, useEffect } from "react";
import { Link,useNavigate  } from "react-router-dom";

export default function Searchbar() {
  const inputRef = useRef();
  const navigate = useNavigate();

  function searchHandler(e) {
    e.preventDefault();
    const value = inputRef.current.value;
    console.log(value)
    inputRef.current.value = "";
    navigate('/searchResults', { state: { val: value, sub: 0} });
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      searchHandler(e);
      // window.location.href = "/searchResults";
    }
  }
  function clearCache(){
    window.location.reload();
  }

  return (
    <div className="searchbar">
      <input
        ref={inputRef}
        type="text"
        placeholder="type something..."
        onKeyDown={handleKeyPress}
      ></input>
      <Link to="/searchResults">
      <button onChange={clearCache} onClick={searchHandler}>SEARCH</button>
      </Link>
    </div>
  );
}
