import comment from "../images/CommentCount.png";
import SingleComment from "./SingleComment";
import axios from 'axios';
import { useEffect, useState } from "react";

export default function Comment({ postId}) {
  const [comments, setComments] = useState([]);
  const [newCommentText, setNewCommentText] = useState("");
  const [newCommentUser, setNewCommentUser] = useState("");
  const [newCommentEmail, setNewCommentEmail] = useState("");
  const [commentNum, setCommentNum] = useState(comments.length)

  useEffect(() => {
    axios
      .get(`/api/posts/${postId}/comments`)
      .then((response) => {
        setComments(response.data);
        console.log(response.data)
        setCommentNum(comments.length)
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  }, [postId, commentNum, comments.length]);

  const createComment = () => {
        axios.post(`/api/posts/${postId}/comments`, {
        postId: postId,
        username: newCommentUser,
        body: newCommentText,
        email: newCommentEmail
      })
      .then((response) => {
        setComments([...comments, response.data]);
        setNewCommentText("");
        setNewCommentEmail("");
        setNewCommentUser("");
      })
      .catch((error) => {
        console.error("Error creating a comment:", error);
      });
  };
















  




  const handleCommentDeletion = (deletedCommentId) => {
    setComments((prevComments) =>
      prevComments.filter((comment) => comment._id !== deletedCommentId)
    );
    setCommentNum(comments.length)
  };

  return (
    <div className="comment">
      <div className="data">
        <img src={comment} style={{ height: "1.4rem" }} alt="" />
        <h2>{commentNum} Comments</h2>
      </div>
      <div className="line-breaking"></div>
      {comments.map((comment, index) => (
        <SingleComment
          key={index}
          username={comment.username}
          commentedDate={comment.createdAt}
          body={comment.body}
          commentId={comment._id}
          onDeleteComment = {handleCommentDeletion}
        />
        
      ))}
      <div className="comment-input">
        <div className="title">New Comment</div>
        <div className="inputbox">
          <div className="fill"></div>
          <textarea
            placeholder="Add a comment..."
            value={newCommentText}
            onChange={(e) => setNewCommentText(e.target.value)}
          ></textarea>
          <div className="fill"></div>
        </div>
        <div className="submit">
          <div className="info">
            <div>NAME</div>
            <input type="text" placeholder="君の名は…" value={newCommentUser} onChange={(e) => setNewCommentUser(e.target.value)}></input>
            <div>EMAIL</div>
            <input type="email" placeholder="イーメール…" value={newCommentEmail} onChange={(e) => setNewCommentEmail(e.target.value)}></input>
          </div>
          <button onClick={createComment}>Comment</button>
        </div>
      </div>
    </div>
  );
}
