import { useEffect, useState} from "react";
import AboutComponent from "../Components/AboutComponent";

const About =() =>{
  return(
    <div className="home">
      <AboutComponent/>
    </div>
  )
}

export default About;