import pfp from "../images/pfp4.jpg";

export default function AboutComponent() {
  return (
    <div className="post" style={{ height: "100%" }}>
      <div className="aboutme">
        {/* <div className="single-post"> */}
        <div className="title">About me</div>
        <img src={pfp} style={{ height: '16rem', width: '12rem', alignSelf: "center", display:
      "flex", borderWidth:"4px", borderStyle:"solid", borderColor:"#412d6151"}} alt="" />
        <div className="aboutmedate">2023@FanExpo</div>
        <div className="body">Anna. <br />Year 1 CS student at University of Toronto.<br />Music Producer.<br />Vocaloid Producer.<br />
        <br />
        <span id="jp2">
        Yorushika</span><br />
        <span id="jp2">Animenz</span><br />
        <span id="jp2">Hatsune Miku</span><br />
        <span id="jp2">桜小路ルナさま♡</span></div>
        <div className="line-breaking"></div>
        <div className="post-footer">
          <div className="last-updated">Last Updated 2023-12-25</div>
        </div>
        </div>
      </div>
    // </div>
  );
}
