import React, { useState, useEffect } from "react";
import md from '../re.md';
import ReactMarkdown from 'react-markdown';


export default function Journals() {
    // const [markdown, setMarkdown] = useState("");
  
    // useEffect(() => {
    //   fetch(md)
    //     .then((res) => res.text())
    //     .then((text) => setMarkdown(text));
    // }, []);
  
    return (
        <div className="home">
        {/* <ReactMarkdown children={markdown} /> */}
      </div>
    );
}