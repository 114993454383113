// import { useRoute } from '@react-navigation/native';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from "axios";
import Pagination from "../Components/Pagination";
import Post from "../Components/Post";

const SearchResults = ({ onPageChange }) => {
  const location = useLocation();
  const { state } = location;
  const { val, sub } = state || {};
  
  const [posts, setPosts] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [commentCount, setCommentCount] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0)
  const [temp, setTemp] = useState("")




  useEffect(() => {
    // setTotalPosts(0)
    console.log("in usefect")
    const fetchPosts = async () => {
      
      const response = await fetch(`/api/posts/allposts?page=${currentPage}&keyword=${val}&subtitle=${sub}`);
      const json = await response.json();

      console.log(val, sub)
      if (val === 0){
        setTemp(sub)
      }else{
        setTemp(val)
      }

      if (response.ok) {
        console.log("json[0]", json[0])
        const tot = json[0].totalPosts;
        
        if (tot!== 0){
          setTotalPosts(tot)
        }
        if(tot !== 0){
          if (tot) {
            setTotalPages(Math.ceil(tot / 5));
            json.shift();
          }
          setPosts(json);
  
          const commentCountPromises = json.map((post) =>
            axios
              .get(`/api/posts/${post.id}/comments`)
              .then((response) => response.data.length)
          );
  
          Promise.all(commentCountPromises)
            .then((commentCounts) => setCommentCount(commentCounts))
            .catch((error) => console.error("Error fetching comments:", error));
        }else{
          setPosts(json)
          setTotalPosts(0)
        }
      }
    };

    fetchPosts();
  }, [currentPage, val, sub]);

  if (!posts || !commentCount) {
    return <p className="loading-data">loading data...</p>;
  }

  if (totalPosts !== 0){
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
      onPageChange(newPage);
    };

    return <div className="home">
    <div className="searchresult">
      <div className="searchval">
      — {temp} — {totalPosts} Results
      </div>
      {console.log(totalPosts)}
      {posts.map((post, index) => (
        <Post
          key={index}
          jsonData={post}
          id={post.id}
          commentCount={commentCount}
          i={index}
        />
      ))}
      <Pagination
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={totalPages}
      />
    </div>
  </div>
    
  }

  
  return(
    <div className='home'>
      <div className="searchresult">
    <div className="searchval">
    — {temp} — {totalPosts} Results
    </div>
    <div className='noresult'>
      No Results were found.
    </div>
    </div>
    </div>
  )

  
};
export default SearchResults;
