import React, { useState } from "react";

const Modal = ({ open, onClose, deleteCommentHandler }) => {
  const [email, setEmail] = useState()
  

  const onChangeHandler = (event) =>{
    setEmail(event.target.value)
  }
  const onSubmit = ()=>{
    deleteCommentHandler(email)
    setEmail("")
  }
  

  if (!open) return null;
  return (
    <>
      <div className="modal-overlay" />
      <div className="modal">
        <div>
          <input type="text" onChange={onChangeHandler} value={email}/>
          <button onClick={onSubmit}>ENTER</button>
        </div>
        <button onClick={onClose}>X</button>
      </div>
    </>
  );
};

export default Modal;
